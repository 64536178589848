import { useQuery } from 'react-query'
import { getClient, listAssignee } from '../../core/apis'
import { SIZE, Tag, VARIANT } from 'baseui/tag'
import { humanizeBudget, parseEtcRequestType, parseGroup, parseProjectCategory, parseProjectType } from '../../core/utils'
import React from 'react'
import { styled } from 'baseui'
import { Block } from 'baseui/block'
import FlexBlock from '../FlexBlock'
import { Spinner } from 'baseui/spinner'
import { LabelLarge, LabelSmall as BaseLabelSmall, LabelXSmall as BaseLabelXSmall } from 'baseui/typography'
import { Drawer } from 'baseui/drawer'
import { Button } from 'baseui/button'
import parsePhoneNumber from 'libphonenumber-js'

const LabelXSmall = (props) => {
	return <BaseLabelXSmall paddingTop="1.6rem" paddingBottom="0.4rem" fontWeight="300" {...props} />
}

const LabelSmall = (props) => {
	return <BaseLabelSmall paddingLeft="5px" paddingTop="6px" {...props} />
}

export default function RequestBody({ paper }) {
	const { client: clientId, group, project_categories: projectCategories, domain, request_type: requestType, request_description, description, budget } = paper
	const { data: client, isSuccess: clientFetched } = useQuery(['clientDetail', { id: clientId }], getClient, { enabled: !!clientId, refetchOnMount: false })
	const { data: assignees, isSuccess: assigneesFetched } = useQuery(['assignees', { clientId, paperId: paper.id }], listAssignee, { enabled: !!clientId, refetchOnMount: false })
	const fetched = Boolean(clientFetched && assigneesFetched)
	const [drawerOpen, setDrawerOpen] = React.useState(false)
	const [drawerContent, setDrawerContent] = React.useState({
		name: 'Unknown',
		phone: '',
		department: '',
		email: '',
		position: '',
		id: null,
	})

	if (!fetched) {
		return (
			<FlexBlock justifyContent="center" alignItems="center" width="100%" height="500px">
				<Spinner $size="50px" $borderWidth="5px" $color="#000" />
			</FlexBlock>
		)
	}

	return (
		<GridWrapper>
			<RowContainer style={{ paddingTop: '40px' }}>
				<Row>
					<LabelXSmall paddingTop={0}>클라이언트</LabelXSmall>
					<LabelSmall>{client?.name}</LabelSmall>
				</Row>
				<Row>
					<LabelXSmall paddingTop={0}>담당자</LabelXSmall>
					{/*<LabelSmall>*/}
					{assignees?.map((user) => (
						<Button
							key={user.id}
							size="mini"
							kind="tertiary"
							onClick={() => {
								setDrawerContent(user)
								setDrawerOpen(true)
							}}
							overrides={{ BaseButton: { style: { fontSize: '14px', textDecoration: 'underline', paddingLeft: 0 } } }}
						>
							{user.name}
						</Button>
					))}
				</Row>
			</RowContainer>
			<Drawer isOpen={drawerOpen} onClose={() => setDrawerOpen(false)} overrides={{ Root: { style: { zIndex: 5 } } }}>
				<LabelLarge>담당자 정보</LabelLarge>
				<LabelXSmall>소속</LabelXSmall>
				<LabelSmall>{client?.name}</LabelSmall>
				<LabelXSmall>이름</LabelXSmall>
				<LabelSmall>{drawerContent.name || '알 수 없음'}</LabelSmall>
				<LabelXSmall>부서</LabelXSmall>
				<LabelSmall>{drawerContent.department || '알 수 없음'}</LabelSmall>
				<LabelXSmall>직책</LabelXSmall>
				<LabelSmall>{drawerContent.position || '알 수 없음'}</LabelSmall>
				<LabelXSmall>이메일</LabelXSmall>
				<LabelSmall>{<a href={`mailto:${drawerContent.email}`}>{drawerContent.email}</a> || '알 수 없음'}</LabelSmall>
				<LabelXSmall>연락처</LabelXSmall>
				<LabelSmall>{drawerContent.phone ? <a href={`tel:${parsePhoneNumber(drawerContent.phone).formatNational()}`}>{parsePhoneNumber(drawerContent.phone).formatNational()}</a> : '알 수 없음'}</LabelSmall>
			</Drawer>
			{/*</LabelSmall>*/}
			<RowContainer>
				<Row>
					<LabelXSmall>의뢰유형</LabelXSmall>
					<LabelSmall>{parseGroup(group)}</LabelSmall>
				</Row>
				{group === 'project' ? (
					<Row>
						<LabelXSmall>예산</LabelXSmall>
						<LabelSmall>{humanizeBudget(budget)}</LabelSmall>
					</Row>
				) : (
					<Row>
						<LabelXSmall>업무종류</LabelXSmall>
						<Block className="tag-wrap">
							<Tag
								variant={VARIANT.solid}
								closeable={false}
								size={SIZE.small}
								overrides={{
									Root: { style: { minWidth: '50px', justifyContent: 'center' } },
								}}
							>
								{parseEtcRequestType(requestType)}
							</Tag>
						</Block>
					</Row>
				)}
			</RowContainer>
			{group === 'project' ? (
				<>
					<ProjectContent domain={domain} projectCategories={projectCategories} startDate={paper.start_date} endDate={paper.end_date} scheduleAdjustable={paper.is_schedule_adjustable} />
				</>
			) : (
				<EtcContent description={request_description} />
			)}
			<LabelXSmall>기타 내용</LabelXSmall>
			<LabelSmall className="request-description">{description || '없음'}</LabelSmall>
		</GridWrapper>
	)
}

function ProjectContent({ projectCategories, domain, startDate, endDate, scheduleAdjustable }) {
	return (
		<>
			<RowContainer>
				<Row>
					<LabelXSmall>일정</LabelXSmall>
					<LabelSmall>
						{startDate.split('-').join('.')}
						<Unit /> ~ {endDate.split('-').join('.')}
					</LabelSmall>
				</Row>
				<Row>
					<LabelXSmall>일정 조율 가능</LabelXSmall>
					<LabelSmall>{scheduleAdjustable ? 'YES' : 'NO'}</LabelSmall>
				</Row>
			</RowContainer>
			<RowContainer>
				<Row>
					<LabelXSmall>업무범위</LabelXSmall>
					<Block className="tag-wrap">
						{projectCategories?.map((row, index) => (
							<Tag
								variant={VARIANT.solid}
								closeable={false}
								size={SIZE.small}
								key={index}
								overrides={{
									Root: {
										style: {
											minWidth: '50px',
											justifyContent: 'center',
											paddingLeft: '10px',
											paddingRight: '10px',
											'@media screen and (max-width: 768px)': {
												fontSize: '12px',
											},
										},
									},
								}}
							>
								{parseProjectCategory(row)}
							</Tag>
						))}
					</Block>
				</Row>
				<Row>
					<LabelXSmall>업무유형</LabelXSmall>
					<Block className="tag-wrap">
						{domain?.map((row, index) => (
							<Tag
								variant={VARIANT.solid}
								closeable={false}
								size={SIZE.small}
								key={index}
								overrides={{
									Root: {
										style: {
											minWidth: '50px',
											justifyContent: 'center',
											'@media screen and (max-width: 768px)': {
												fontSize: '12px',
											},
										},
									},
								}}
							>
								{parseProjectType(row)}
							</Tag>
						))}
					</Block>
				</Row>
			</RowContainer>
		</>
	)
}

function EtcContent({ description }) {
	return (
		<>
			<LabelXSmall>추가업무내용</LabelXSmall>
			<LabelSmall style={{ whiteSpace: 'pre-wrap' }}>{description || '없음'}</LabelSmall>
		</>
	)
}

const GridWrapper = styled('div', {
	width: '765px',
	maxWidth: '100%',
	flex: '1 0 auto',
})

const RowContainer = styled('div', {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
})
const Row = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	width: '50%',
	paddingRight: '15px',
	boxSizing: 'border-box',
})
const Unit = styled('span', {
	display: 'inline',
	'@media screen and (max-width: 768px)': {
		display: 'block',
		height: '6px',
	},
})
