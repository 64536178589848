import React, { useEffect, useState } from 'react'
import { getProjectsDATA } from '../../core/apis'
import Layout from '../../components/Layout'
import { Block } from 'baseui/block'
import ContentHeader from '../../components/ContentHeader'
import MovingProjectChart from '../../components/Dashboard/MovingProjectChart'
import DetailPropjectCount from '../../components/Dashboard/DetailPropjectCount'
import { ArcElement, BarElement, CategoryScale, Chart, Legend, LinearScale, Tooltip } from 'chart.js'
import { styled } from 'styletron-react'
import { CountBox } from '../../components/Dashboard/CountBox'
import TaskRatioChart from '../../components/Dashboard/TaskRatioChart'
import { withStyle } from 'baseui'

export default function Dashboard({ props }) {
	const [isLoad, setIsLoad] = useState(false)
	const [result, setResult] = useState()

	useEffect(() => {
		if (isLoad) return

		getProjectsDATA().then((data) => {
			console.log(data);
			setResult(data)
		})
		setIsLoad(true)
		Chart.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, ArcElement)

		return () => {
			Chart.unregister(CategoryScale, LinearScale, BarElement, Tooltip, Legend, ArcElement)
		}
	}, [])

	return (
		<Layout>
			<ContentHeader title={'대시보드'} />
			<Wrap>
				{result && (
					<Block>
						<DetailCountContent style={{ backgroundColor: 'transparent', margin: 0 }}>{result.detail && <DetailPropjectCount total={result.total_count} detail={result.detail} />}</DetailCountContent>
						<Container style={{ position: 'relative', zIndex: 0 }}>
							{result.task_ratio && <TaskRatioContent>{result.task_ratio && <TaskRatioChart taskRatio={result.task_ratio} />}</TaskRatioContent>}
							<CountBoxContent>
								{result.budget_avg && <CountBox delay={0.6} title="평균 예산" count={`${result.budget_avg}`} child="단위 : 백만원" style={{ width: '100%', height: '50%', boxSizing: 'border-box' }} />}
								{result.period_avg && <CountBox delay={0.75} title="평균 일정" count={result.period_avg} child="단위 : 일" style={{ width: '100%', height: '50%', boxSizing: 'border-box' }} />}
							</CountBoxContent>
						</Container>
						<MovingProjectContent>{result.moving_projects_count && <MovingProjectChart movingProjects={result.moving_projects_count} />}</MovingProjectContent>
					</Block>
				)}
			</Wrap>
		</Layout>
	)
}

const Wrap = styled('div', {
	maxWidth: '1400px',
	width: '100%',
	margin: '0 auto',
})

const Content = styled('div', {
	width: '100%',
	margin: '1rem 0 0',
	borderRadius: '20px',
	// border: '1px solid red',
	backgroundColor: '#fff',
	transition: 'all 450ms cubic-bezier(0.25, 1, 0.5, 1)',
	boxShadow: 'rgb(225 225 225 / 40%) 0px 3px 10px',
	transform: 'scale(1)',
	userSelect: 'none',
	':active': {
		transform: 'scale(0.985)',
		boxShadow: 'none',
		transition: 'all 400ms cubic-bezier(0.25, 1, 0.5, 1)',
	},
})

const MovingProjectContent = withStyle(Content, {
	position: 'relative',
	padding: '4rem',
	marginBottom: '5rem',
	boxSizing: 'border-box',
	zIndex: 2,
	'@media screen and (max-width:768px)': {
		padding: '1.5rem',
	},
})

const TaskRatioContent = withStyle(Content, {
	width: '75%',
	padding: '4rem',
	boxSizing: 'border-box',
	'@media screen and (max-width:768px)': {
		width: '100%',
		padding: '2rem 1.5rem',
	},
})
const DetailCountContent = styled('div', {
	width: '100%',
	margin: '1rem 0 0',
	borderRadius: '20px',
	backgroundColor: '#fff',
})
const CountBoxContent = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	width: 'calc(25% - 1rem)',
	height: 'auto',
	margin: '1rem 0 0',
	borderRadius: '20px',
	gap: '1rem',
	backgroundColor: 'transparent',
	'@media screen and (max-width:768px)': {
		width: '100%',
		flexDirection: 'row',
	},
})

const Container = styled('div', {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
})
