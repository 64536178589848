export const zIndexes = {
    modal: 20,
}

export const StateChoices = Object.freeze({
    NEW: 'new',
    ALIGNING: 'aligning',
    IN_PROGRESS: 'in_progress',
    REJECTED: 'rejected',
    COMPLETED: 'completed',
})
export const StatusEnum = Object.freeze({
    new: '대기',
    aligning: '접수',
    in_progress: '진행',
    // cancelled: '취소',
    rejected: '거절',
    completed: '종료',
})


export const TeamTagEnum = Object.freeze({
    UX: 'UX',
    UI: 'UI',
    BX: 'BX',
    DX: 'DX',
})
export const RequestOptionEnum = Object.freeze({
    group: {
        project: 'project',
        etc: 'etc'
    },
    project: {
        tasks: {
            UXUI: [
                {label: '웹', value: 'web'},
                {label: '앱', value: 'app'},
                {label: 'BE', value: 'backend_development'},
                {label: 'FE', value: 'frontend_development'},
            ],
            BX: [
                {label: '브랜드 전략', value: 'brand_strategy'},
                {label: '브랜드 네임 & 슬로건', value: 'brand_name_slogan'},
                {label: '주요 매체별 컨셉 디자인', value: 'media_concept_design'},
                {label: '브랜드 아이덴티티 디자인', value: 'brand_identity_design'},
                {label: '패키지 디자인', value: 'brand_package_design'},
                {label: '그래픽 적용 디자인', value: 'graphic_design'},
                {label: '프린트 적용 디자인', value: 'print_design'},
            ],
            ETC: [
                {label: '기타', value: 'etc'}
            ],
        },
        range: [
            {label: '컨설팅', value: 'consulting'},
            {label: '신규 구축', value: 'building'},
            {label: '개선 구축', value: 'solution'},
            {label: '유지보수/운영', value: 'maintenance'},
            {label: '기타', value: 'etc'},
        ],
        budget: [
            {label: '5천만 원 미만', value: 25},
            {label: '5천만 ~ 1억', value: 75},
            {label: '1억 ~ 2억', value: 150},
            {label: '2억 ~ 3억', value: 250},
            {label: '3억 ~ 4억', value: 350},
            {label: '4억 ~ 5억', value: 450},
            {label: '5억 이상', value: 750},
        ]
    },
    etc: [
        {label: '언론 홍보', value: 'media_promotion'},
        {label: '인터뷰 요청', value: 'interview'},
        {label: '세미나 요청', value: 'seminar'},
        {label: '마케팅 업무', value: 'marketing'},
        {label: '개발사 협조', value: 'cooperation'},
        {label: '기타', value: 'etc'},
    ]
});