import React from 'react'
import { useStyletron } from 'baseui'
import { ListItem as BaseListItem, ListItemLabel as BaseListItemLabel } from 'baseui/list'
import { Block } from 'baseui/block'

import { CheckCircleIcon } from '@heroicons/react/outline'
import FlexBlock from '../FlexBlock'
import SortPopover from './SortPopover'
import CheckboxPopover from '../CheckboxPopover'
import { StatusEnum } from '../../core/options'
import { useQuery, useQueryClient } from 'react-query'
import { detailPaper, listNotes, listPapers } from '../../core/apis'
import { LabelSmall } from 'baseui/typography'
import { Pagination, SIZE } from 'baseui/pagination'
import { history } from '../../routes'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'qs'
import { StyledLink } from 'baseui/link'
import { useSnackbar } from 'baseui/snackbar'
import StatusTag from '../StatusTag'
import { Spinner } from 'baseui/spinner'
import { sinceFromNow } from '../../core/utils'
import spacetime from 'spacetime'

const ListItem = ({ commentCount, ...props }) => {
	return (
		<BaseListItem
			overrides={{
				Root: {
					style: ({ $theme }) => ({
						borderTopStyle: $theme.borders.border200.borderStyle,
						borderTopColor: $theme.borders.border200.borderColor,
						borderTopWidth: $theme.borders.border200.borderWidth,
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'flex-start',
					}),
					// props: ({children, ...rest}) => ({
					//     children: <>
					//         <Block id='foo' display="flex" width='100%'>{children}</Block>
					//         <Block $style={{
					//             borderTopStyle: theme.borders.border200.borderStyle,
					//             borderTopColor: theme.borders.border200.borderColor,
					//             borderTopWidth: theme.borders.border200.borderWidth,
					//             width: '100%',
					//             height: '30px',
					//         }}><AnnotationIcon width='20px' />
					//             {commentCount}</Block>
					//     </>,
					//     ...rest,
					// })
				},
				ArtworkContainer: {
					style: {
						marginLeft: '1rem',
						width: 'max-content',
						marginTop: '10px',
						alignItems: 'flex-start',
						'@media screen and (max-width: 768px)': {
							marginLeft: '15px',
						},
					},
				},
				EndEnhancerContainer: {
					style: {
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginRight: '0',
						width: '40px',
						minWidth: 'max-content',
						padding: '1px 1rem 0 6px',
						boxSizing: 'content-box',
						whiteSpace: 'nowrap',
						'@media screen and (max-width: 768px)': {
							padding: '2px 15px 0 6px',
						},
					},
				},
				Content: {
					style: {
						display: 'flex',
						minHeight: 'unset',
						alignItems: 'flex-start',
						padding: '12px 0',
						marginLeft: '.3rem',
						borderBottomStyle: 'none',
						'@media screen and (max-width: 768px)': {
							padding: '14px 0',
						},
					},
				},
			}}
			{...props}
		/>
	)
}

const ListItemLabel = (props) => {
	return (
		<BaseListItemLabel
			overrides={{
				LabelContent: {
					style: ({ $theme }) => $theme.typography.LabelMedium,
				},
				LabelDescription: {
					style: ({ $theme }) => ({
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						color: $theme.colors.contentTertiary,
						...$theme.typography.font100,
					}),
				},
			}}
			{...props}
		/>
	)
}

function RequestListItem({ row }) {
	const [, theme] = useStyletron()
	const queryClient = useQueryClient()
	const { enqueue } = useSnackbar()
	const navigate = useNavigate()

	const createdAt = spacetime(new Date(row.created_datetime), 'Asia/Seoul')

	function handleLink(id) {
		return () => {
			Promise.all([queryClient.prefetchQuery(['paperDetail', { id: row.id }], detailPaper), queryClient.prefetchQuery(['noteList', { id: row.id }], listNotes)])
				.then(() => {
					navigate(`/papers/${row.id}`)
				})
				.catch((e) => {
					enqueue(
						{
							message: '유효하지 않은 접근입니다',
							overrides: { Content: { style: { backgroundColor: theme.colors.negative500 } } },
						},
						3000
					)
				})
		}
	}

	return (
		<ListItem
			// endEnhancer={() =>
			// 	row.notes_count > 0 && (
			// 		<Block>
			// 			<AnnotationIcon width="20px" />
			// 			<span style={{ verticalAlign: 'super', marginLeft: '5px', fontSize: '12px' }}>{String(row.notes_count).padStart(2, '0')}</span>
			// 			{/*<Avatar name='U X' size="scale900" overrides={{Root: {style: {border: '1px solid #fff', position: 'relative', zIndex: '3'}}}} />*/}
			// 			{/*<Avatar name='U I' size="scale900" overrides={{Root: {style: {border: '1px solid #fff', marginLeft: '-7px', position: 'relative', zIndex: '2'}}}} />*/}
			// 			{/*<Avatar name='D X' size="scale900" overrides={{Root: {style: {border: '1px solid #fff', marginLeft: '-7px', position: 'relative', zIndex: '1'}}}} />*/}
			// 		</Block>
			// 	)
			// }

			artwork={() => <StatusTag status={row.state} />}
			commentCount={0}
		>
			<Block className="flex-row">
				<ListItemLabel
					description={<span>{row.client.name}</span>}
					overrides={{
						LabelDescription: {
							style: {
								padding: '4px 0 0',
								color: '#333',
								fontSize: '13px',
								'@media only screen and (max-width: 500px)': {
									fontSize: '12px',
								},
							},
						},
						StyledLink: {
							style: {
								border: '1px solid red',
								fontSize: '20px',
							},
						},
					}}
				>
					<StyledLink
						animateUnderline={true}
						onClick={handleLink(row.id)}
						overrides={{
							Root: {
								style: {
									fontSize: '30px',
								},
							},
						}}
					>
						{row.title}
					</StyledLink>
					<span>{row.notes_count > 0 ? ` [${row.notes_count}]` : ''}</span>
				</ListItemLabel>
			</Block>
			<Block className="flex-row row-date">{createdAt.unixFmt('yyyy-MM-dd HH:mm').split('-').join('.')}</Block>
		</ListItem>
	)
}

export default function RequestList({ onChangeOpen, ...rest }) {
	const loc = useLocation()
	const initialQuery = qs.parse(loc.search, { ignoreQueryPrefix: true })
	const [css, theme] = useStyletron()
	const initialPage = initialQuery.page ? parseInt(initialQuery.page) : 1
	const navigate = useNavigate()

	const [page, setPage] = React.useState(initialPage)
	const [isOpen, setIsOpen] = React.useState(true)

	const [queryParams, setQueryParams] = React.useState({ page, is_open: isOpen })
	const {
		isLoading,
		isSuccess,
		data = {},
	} = useQuery(['papers', queryParams], listPapers, {
		getNextPageParam: (lastPage) => {
			if (lastPage.next) {
				const { page } = qs.parse(lastPage.next.split('?')[1])
				return parseInt(page)
			}
			return false
		},
		keepPreviousData: true,
		staleTime: 1000 * 6,
		onError: (e) => {
			switch (e.response?.status) {
				case 400:
					console.dir(e)
					break
				case 401:
					navigate('/accounts/signin')
					break
				case 404:
					setPage(1)
					handleSetQueryParams({ page: 1 })
					history.replace('?page=1')
					break
			}
		},
		select: (data) => {
			data.results = data.results.map((row) => ({ ...row, since: sinceFromNow(row.created_datetime) }))
			return data
		},
	})

	const { open_count: openCount, closed_count: closedCount, results = [], next, previous } = data || {}

	function handleOpenFilter(isOpen) {
		setIsOpen(isOpen)
		onChangeOpen && onChangeOpen(isOpen)
		handleSetQueryParams({ is_open: isOpen })
	}

	function handleSetQueryParams(param) {
		setQueryParams((prevState) => ({ ...prevState, ...param }))
	}

	function removeQueryParam(key) {
		setQueryParams((prevState) => {
			const { ...newState } = prevState
			delete newState[key]
			return newState
		})
	}

	if (isLoading) {
		return (
			<FlexBlock justifyContent="center" alignItems="center" width="100%" height="500px">
				<Spinner $size="50px" $borderWidth="5px" $color="#000" />
			</FlexBlock>
		)
	}

	return (
		<>
			<Block overflow="hidden" className={css({ borderRadius: '10px', boxShadow: 'rgb(225 225 225 / 40%) 0px 3px 10px' })}>
				{/*HEADER*/}
				<FlexBlock padding="0.7rem" height="50px" alignItems="center" backgroundColor="rgba(255, 255, 255, 0.6)">
					<FlexBlock
						className={css({
							// cursor: 'pointer',
							...theme.typography.font200,
							'@media only screen and (max-width: 450px)': {
								display: 'none',
							},
						})}
						color={isOpen ? theme.colors.mono1000 : theme.colors.mono700}
						// onClick={() => (handleOpenFilter(true))}
					>
						<CheckCircleIcon width="18px" className={css({ margin: '0 .5rem' })} />
						검색결과 {openCount + closedCount || 0} 개
					</FlexBlock>
					{/*<FlexBlock*/}
					{/*    className={css({*/}
					{/*        cursor: 'pointer',*/}
					{/*        ...theme.typography.font200,*/}
					{/*        "@media only screen and (max-width: 450px)": {*/}
					{/*            display: 'none',*/}
					{/*        }*/}
					{/*    })}*/}
					{/*    color={!isOpen ? theme.colors.mono1000 : theme.colors.mono700}*/}
					{/*    onClick={() => (handleOpenFilter(false))}*/}
					{/*>*/}
					{/*    <MinusCircleIcon width='18px' className={css({margin: '0 .5rem'})} />{closedCount || 0} Closed*/}
					{/*</FlexBlock>*/}

					<FlexBlock className={css({ marginLeft: 'auto', alignItems: 'center', height: '100%' })} color={theme.colors.mono700}>
						<SortPopover
							onItemSelect={(item) => {
								if (item.value === 'publication_asc') {
									handleSetQueryParams({ ordering: 'created_datetime' })
								} else {
									removeQueryParam('ordering')
								}
							}}
						/>
					</FlexBlock>
					{/*<FlexBlock className={css({marginLeft: '30px'})} color={theme.colors.mono700}>*/}
					{/*    <FilterPopover*/}
					{/*        title="조직별"*/}
					{/*        data={Object.entries(TeamTagEnum).map(([value, label]) => ({value, label}))}*/}
					{/*        onChange={(checkedInputs, allChecked) => {*/}
					{/*            // TODO*/}
					{/*            console.log(checkedInputs);*/}
					{/*            console.log(allChecked ? '전부': '일부')*/}
					{/*        }}*/}
					{/*    />*/}
					{/*</FlexBlock>*/}
					<FlexBlock className={css({ marginLeft: '30px', marginRight: '10px', height: '100%' })} color={theme.colors.mono700}>
						<CheckboxPopover
							title="상태별"
							data={Object.entries(StatusEnum).map(([value, label]) => ({ value, label }))}
							onChange={(checkboxes, allChecked) => {
								if (allChecked) {
									removeQueryParam('state')
								} else {
									let status = Object.entries(checkboxes)
										.filter(([k, v]) => v)
										.map(([k, v]) => k)
									// const status = checkboxes.map(input => input.value);
									if (!status.length) status = 'NULL'
									handleSetQueryParams({ state: status })
								}
							}}
							className={css({ height: '100%' })}
						/>
					</FlexBlock>
				</FlexBlock>
				{/*END HEADER*/}
				{isSuccess && results.length > 0 ? (
					results.map((row, idx) => <RequestListItem key={row.id} row={row} />)
				) : (
					<FlexBlock width="100%" height="100px" backgroundColor={theme.colors.backgroundPrimary} alignItems="center" justifyContent="center">
						<LabelSmall color="contentTertiary">검색 결과가 없습니다</LabelSmall>
					</FlexBlock>
				)}
			</Block>
			{Boolean(next || previous) && isSuccess && (
				<Pagination
					numPages={data.num_pages}
					size={SIZE.mini}
					currentPage={page}
					onPageChange={({ nextPage }) => {
						const { num_pages } = data
						const pageNum = Math.min(Math.max(nextPage, 1), num_pages)
						setPage(pageNum)
						handleSetQueryParams({ page: pageNum })
						history.push(`?page=${pageNum}`, { page: pageNum })
					}}
					overrides={{
						Root: {
							style: {
								justifyContent: 'center',
								margin: '1rem 0',
							},
						},
					}}
				/>
			)}
		</>
	)
}
